.footer {
    padding-bottom: 24px;
    padding-top: 30px;
    margin-top: var(--s-xl);
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    font-size: var(--f-m);
}

.item+.item {
    margin-left: 20px;
}

.items {
    display: flex;
    width: 100%;
}

@media (min-width: 400px) {
    .items {
        width: auto;
    }
}

.item {
    text-align: center;
}

@media (min-width: 600px) {
    .code {
        display: inline-block !important;
    }
}