import { useEffect, useState } from 'preact/hooks'
import throttle from '../scripts/throttle'

const useMoveUpImage = (className: string, scrollSpeed: number) => {
    let ticking = false
    const speed = scrollSpeed

    useEffect(() => {
        document.addEventListener('scroll', tick)

        return () => document.removeEventListener('scroll', tick)
    }, [])

    const tick = throttle(() => {
        if (!ticking) {
            window.requestAnimationFrame(() => {
                rotateImage()
                ticking = false
            })

            ticking = true
        }
    }, 16) // Thrott le to 16 milliseconds

    const rotateImage = () => {
        const theta = document.documentElement.scrollTop * speed
        const element: HTMLElement = document.getElementById(className)

        element.style.filter = `grayscale(${theta / 90})`
        element.style.transform =
            'scale(' +
            (1 + theta / 1200) +
            ')' +
            'translateY(' +
            theta * -1 +
            'px)'
    }
}

export default useMoveUpImage
