import { h } from 'preact'
import * as classes from './Garden.module.css'
import useMoveUpImage from '../../hooks/useMoveUpImage'

import flower1 from '../../assets/images/foxglove_blue_bosch.png'
import flower2 from '../../assets/images/foxglove_bosch.png'
import flower3 from '../../assets/images/foxglove_many_flowers.png'
import flower4 from '../../assets/images/foxglove_green.png'
import flower5 from '../../assets/images/foxglove_rainbow.png'

const flowers = [
    {
        img: flower1,
        position: {
            top: '10px',
            left: '8vw',
        },
        width: 130,
        alt: '',
    },
    {
        img: flower2,
        position: {
            top: '30px',
            left: '20vw',
        },
        width: 190,
        alt: '',
    },
    {
        img: flower3,
        position: {
            top: '-50px',
            left: '70vw',
        },
        width: 190,
        alt: '',
    },
    {
        img: flower4,
        position: {
            top: '30px',
            left: '81vw',
        },
        width: 150,
        alt: '',
    },
    {
        img: flower5,
        position: {
            top: '75px',
            left: '2vw',
        },
        width: 110,
        alt: '',
    },
]

const Garden = () => {
    useMoveUpImage('flower-0', 0.5)
    useMoveUpImage('flower-1', 0.81)
    useMoveUpImage('flower-2', 0.8)
    useMoveUpImage('flower-3', 0.5)
    useMoveUpImage('flower-4', 0.6)

    return (
        <div className={classes.garden}>
            {flowers.map((flower, i) => (
                <img
                    src={flower.img}
                    width={flower.width}
                    style={{
                        top: flower.position.top,
                        left: flower.position.left,
                    }}
                    className={`${classes.flower} shake`}
                    id={`flower-${i}`}
                    key={i}
                    alt={flower.alt}
                />
            ))}
        </div>
    )
}

export default Garden
