import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import * as classes from './Loading.module.css'

import flower from '../../assets/images/foxglove_loader.png'
import { LOCAL_STORAGE_KEY_INITIAL_LOAD } from '../../core/Config'

const Loading = () => {
    useEffect(() => {
        document.body.classList.add('loading')
    

        // if key does not exist, it is the first page load
        if (!localStorage.getItem(LOCAL_STORAGE_KEY_INITIAL_LOAD)) {
            document.body.classList.add('initalLoading')
            localStorage.setItem(LOCAL_STORAGE_KEY_INITIAL_LOAD, 'true')
        }

        return () => {
            document.body.classList.remove('loading')
            document.body.classList.remove('initalLoading')
        }
    }, [])

    return (
        <div className={classes.wrapper}>
            <div class={classes.emoji}>
                <img width="110" src={flower} />
            </div>
        </div>
    )
}

export default Loading
