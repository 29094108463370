.header {
    grid-template-areas: "claim claim burger"
        "type type type";
    padding-top: 24px;
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    position: relative;
    z-index: var(--z-highest);

}

@media (min-width: 700px) {
    .header {
        grid-template-areas: "claim type burger"
    }
}

.claim {
    grid-area: claim;
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--f-l);
}

section,
nav> * {
    /* play with this kind of animation for types :)*/
    animation: 0.333s ease-out slideInFromBottom;
}

.largeType {
    grid-area: type;
    font-size: var(--f-xl);
    margin-top: 22vh;
    color: #000000bd;
    animation-fill-mode: forwards;
    opacity: 0;
}


@keyframes slideInFromBottom {
    from {
        transform: translateY(12px);
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}


@media (min-width: 700px) {
    .largeType {
        margin-left: 12.5vw;
        margin-right: 28vw;
        margin-top: auto;
    }
}

.burger {
    grid-area: burger;
    text-align: right;
}

.burgerSymbol {
    color: hotpink
}