import { h } from 'preact'
import BurgerMenu from '../BurgerMenu/BurgerMenu'
import * as PubSub from 'pubsub-js'
import * as classes from './Header.module.css'
import { useEffect, useState } from 'preact/hooks'

const Header = () => {
    const [currentLang, setCurrentLang] = useState()

    const change = () => {
        if (currentLang === 'de') PubSub.publish('LANGUAGE', 'en')
        if (currentLang === 'en') PubSub.publish('LANGUAGE', 'de')
        if (!currentLang) PubSub.publish('LANGUAGE', 'de')

        console.log(currentLang)
    }

    useEffect(() => {
        const sub = PubSub.subscribe('LANGUAGE', mySubscriber)
        return () => PubSub.unsubscribe(sub)
    }, [])

    const mySubscriber = (msg: any, data: any) => {
        setCurrentLang(data)
    }

    return (
        
        <header className={classes.header}>
            {/*<Link className={classes.claim} href={PAGES_EN.HOME}>
                🛖
            </Link>*/}

            <div className={classes.burger}>
                <BurgerMenu />
            </div>
        </header>
    )
}

export default Header
