.garden {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    user-select: none;
}

.flower {
    position: fixed;
    filter: grayscale(0.55);
    will-change: transform;
}

/* hides flower-0 image on mobile :) */
.garden>img:nth-child(3) {
    display: none;
}

@media (min-width: 600px) {
    .garden>img:nth-child(3) {
        display: block;
    }
}

/* fixes an aligent issue:) with the side plant */
@media (min-width: 600px) {
    .garden>img:nth-child(4) {
        left: 84vw !important;
    }
}