import { h, render } from 'preact'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Routes from './core/Routes'
import { IntlProvider } from 'preact-jsx-i18n'
import defaultDefinition from '../src/core/language/en.json'
import { useEffect, useState } from 'preact/hooks'

// test out the regular i18n for preact also try out JSON again!

// add a switch to DE
const App = () => {
    const [definition, setDefinition] = useState(defaultDefinition)

    useEffect(() => {
        const sub = PubSub.subscribe('LANGUAGE', mySubscriber)
        return () => PubSub.unsubscribe(sub)
    }, [])

    const mySubscriber = (msg: any, data: 'de' | 'en') => {
        if (data === 'de') {
            import(`../src/core/language/de.json`).then((definition) =>
                setDefinition(definition),
            )
        } else {
            import(`../src/core/language/en.json`).then((definition) =>
                setDefinition(definition),
            )
        }
    }

    return (
        <IntlProvider dictionary={definition}>
            <Header />

            <Routes />
            <Footer />
        </IntlProvider>
    )
}

render(<App />, document.getElementById('root'))
