.container {
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    margin-top: var(--component-spacing-large);
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
}

.container:first-of-type {
    margin-top: 24vh;
    padding-top: 40px;

}

/* fix this hack :) this corrects spacings for seperator component */
.container:only-child {
    padding-top: 0;
    margin-top: var(--component-spacing-large);
}