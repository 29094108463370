import { h } from 'preact'
import Router from 'preact-router'
import AsyncRoute from 'preact-async-route'
import Loading from '../components/Loading/Loading'
import useLocale from '../hooks/useLocale'
import { getRoutesByLocale } from './Pages'
import Garden from '../components/Garden/Garden'

// TODO: generate routes from route array?
const HOME = () => import('../screens/Home').then((module) => module.default)
const ABOUT = () => import('../screens/About').then((module) => module.default)
const CODE = () => import('../screens/Code').then((module) => module.default)
const LEGAL = () => import('../screens/Legal').then((module) => module.default)
const CONTACT = () =>
    import('../screens/Contact').then((module) => module.default)
const PLAN = () => import('../screens/Plan').then((module) => module.default)

const Routes = () => {
    const locale = useLocale()
    const routes = getRoutesByLocale(locale)

    const handleRoute = () => {
        if (typeof window.scrollTo === 'function') {
            window.scrollTo({ top: 0, left: 0 })
        }
    }

    return (
        <main>
            <Garden />
            <Router onChange={handleRoute}>
                <AsyncRoute
                    path={routes.HOME}
                    getComponent={HOME}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.ABOUT}
                    getComponent={ABOUT}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.CODE}
                    getComponent={CODE}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.LEGAL}
                    getComponent={LEGAL}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.CONTACT}
                    getComponent={CONTACT}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.PLAN}
                    getComponent={PLAN}
                    loading={() => <Loading />}
                />
            </Router>
        </main>
    )
}

export default Routes
